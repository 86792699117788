import SocialMedia from "../../common/social_media/SocialMedia"
import AboutUs from '../../common/about_us/index'
import BannerSettings from "../../common/banner"

export default function MainPageLayout() {
  return (
    <div>
        <BannerSettings/>
        <SocialMedia/>
        <AboutUs/>
    </div>
  )
}
