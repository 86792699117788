import React, {useState} from 'react';
import { useQuery } from 'react-query';
import {useStyles} from './styles';
import TableRow from './table_row';
import fetchDeals from '../../../../services/otc/fetchDeals';
import dealAction from '../../../../services/otc/dealAction';
import { adminCompleteDealUsd,adminCompleteDeal } from '../../../../smart/smartOtc';
import { IDeal } from '../../../../types/global_types';

const UsersTable = () => {
    const {data} = useQuery('users-list',fetchDeals)
    const [isUpdateModal, setIsUpdateModal] = useState(false)
    const [loading,setLoading] = useState<boolean>(false)
    const items : Array<IDeal> = data?.data?.deals || []

    const confirmCompleteDeal = async (deal:IDeal,isReturn:boolean) : Promise<void> => {
        setLoading(true)

        const isSuccess = 
        deal.ticker === 'usd'
        ?
        await adminCompleteDealUsd(deal.dealId,isReturn)
        :
        await adminCompleteDeal(deal.dealId,isReturn)

        setLoading(false)
    }

    const {
        wrapper,
        headerWrapper,
        statusWrapper,
        userWrapper,
        walletWrapper,
        pointsWrapper,
    } = useStyles()

    return (
        <div className={wrapper}>
            <div className={`container ${headerWrapper}`}>
                <div className={userWrapper}>Deal creater</div>
                <div className={userWrapper}>Buyer</div>
                <div className={walletWrapper}>Creator address</div>
                <div className={walletWrapper}>Buyer address</div>
                <div className={statusWrapper}>Status</div>
                <div className={pointsWrapper}>Price</div>
                <div className={pointsWrapper}>Name</div>
            </div>
            <div>
                {
                    items?.map((item:IDeal) => {
                        return <TableRow 
                        key={item._id}
                        deal={item}
                        confirmCompleteDeal={confirmCompleteDeal} />
                    })
                }
            </div>
            {/* {isUpdateModal && <UpdateUserModal
                onClose={() => setIsUpdateModal(false)}
            />} */}
        </div>
    );
};

export default UsersTable;